// @ts-ignore
exports.onInitialClientRender = () => {
  if (process.env.NODE_ENV === 'production' && process.env.mode !== 'staging') {
    const script = document.createElement('script');
    script.innerHTML = `(function(){var w=window;if(w.ChannelIO){return w.console.error("ChannelIO script included twice.")}var ch=function(){ch.c(arguments)};ch.q=[];ch.c=function(args){ch.q.push(args)};w.ChannelIO=ch;function l(){if(w.ChannelIOInitialized){return}w.ChannelIOInitialized=true;var s=document.createElement("script");s.type="text/javascript";s.async=true;s.src="https://cdn.channel.io/plugin/ch-plugin-web.js";var x=document.getElementsByTagName("script")[0];if(x.parentNode){x.parentNode.insertBefore(s,x)}}if(document.readyState==="complete"){l()}else{w.addEventListener("DOMContentLoaded",l);w.addEventListener("load",l)}})();`;
    document.body.appendChild(script);

    // @ts-ignore
    window?.ChannelIO('boot', { pluginKey: process.env.GATSBY_CHANNEL_PLUGIN_KEY });

    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_ANALYTICS_ID}`;
    document.body.appendChild(gtagScript);

    const analyticsScript = document.createElement('script');
    analyticsScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      
      const APP_ID = "${process.env.GATSBY_ANALYTICS_ID}";
      gtag('js', new Date());
      gtag('config', APP_ID);
    `;
  }
};
